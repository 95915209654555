import React, { PropsWithChildren, VFC, useMemo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { fileTransferConst } from '../../constants/fileTransferConstants';
import FAIcon from '../FAIcon/FAIcon';
import ActionLink from '../ActionLink/ActionLink';

import './FileLink.css';

interface FileLinkProps {
	fileId: number;
	status?: string;
	fileName?: string;
	saved?: boolean;
	eventAssetId?: number;
	displayIcon?: boolean;
	className?: string;
}

const FileLink: VFC<PropsWithChildren<FileLinkProps>> = ({
	children,
	fileId,
	fileName,
	status,
	saved,
	eventAssetId,
	displayIcon,
	className,
}) => {
	let href = eventAssetId
		? `${global.config.apiUrl}/marketing/events/products/assets/${eventAssetId}/files/${fileId}`
		: `${global.config.sfsUrl}/files/${fileId}`;

	if (saved) {
		href += '?downloadable=true';
	}
	const statusMessage = useMemo(() => {
		switch (status) {
			case fileTransferConst.TRANSFER_STATES.FAILED:
			case fileTransferConst.TRANSFER_STATES.DELETED:
				return 'File upload failed';
			case fileTransferConst.TRANSFER_STATES.INFECTED:
				return 'File cannot be viewed; failed automatic virus check';
			case fileTransferConst.TRANSFER_STATES.WAITING:
				return 'File is awaiting upload or is in processing, please wait.';
			case fileTransferConst.TRANSFER_STATES.COMPLETED:
			default:
		}
		return '';
	}, [status]);
	return (
		<span className="text-nowrap">
			{displayIcon && status === fileTransferConst.TRANSFER_STATES.WAITING && (
				<FAIcon
					name="file-upload"
					variant="solid"
					style={{ color: 'var(--nin-color-ltgray1)' }}
				/>
			)}
			{displayIcon && status === fileTransferConst.TRANSFER_STATES.FAILED && (
				<FAIcon name="file" variant="solid" className="text-danger mr-1" />
			)}
			{displayIcon && status === fileTransferConst.TRANSFER_STATES.DELETED && (
				<FAIcon name="file" variant="solid" className="text-danger" />
			)}
			{displayIcon && (status === fileTransferConst.TRANSFER_STATES.COMPLETED || !status) && (
				<FAIcon name="file" variant="light" />
			)}
			{displayIcon && status === fileTransferConst.TRANSFER_STATES.INFECTED && (
				<FAIcon name="file-exclamation" variant="solid" className="text-danger" />
			)}
			{displayIcon && <>&nbsp;</>}
			{status === fileTransferConst.TRANSFER_STATES.COMPLETED ? (
				<a
					href={href}
					download={fileName}
					target={saved ? undefined : '_blank'}
					rel="noreferrer"
					className={className}
				>
					{children}
				</a>
			) : (
				<OverlayTrigger
					placement="right"
					overlay={(props) => (
						<Tooltip {...props} id={'tooltip' + fileId}>
							{statusMessage}
						</Tooltip>
					)}
				>
					<ActionLink className="px-0" dotted>
						{children}
					</ActionLink>
				</OverlayTrigger>
			)}
		</span>
	);
};

export default FileLink;
