import React, { useMemo } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import FAIcon from '../../components/FAIcon/FAIcon';
import FilterableTable from '../../components/FilterableTable/FilterableTable';
import Page from '../../components/Page/Page';
import Title from '../../components/Title/Title';
import { companyAgreementConstants } from '../../constants/companyAgreementConstants';
import { platformCodes, platformFeatures } from '../../constants/platformConstants';
import { useUserProfile } from '../../hooks/reduxHooks';
import { getAllCompanies } from '../../services/companiesService';
import {
	getDigitalOrders,
	getDigitalOrdersPlatformLegend
} from '../../services/digitalCodesService';
import { validateActiveAgreementType } from '../../utils/companyUtils';
import { getPermissions } from '../../utils/digitalCodesUtils';
import {
	getActivationStatus,
	getFiltersConfig,
	getTableFormat
} from './DigitalCodesManagement.helpers';
import { getAvailableFeaturePlatforms } from '../../utils/userUtils';
import { useCompanyAgreementsQuery } from '../../hooks/queryHooks';


const DigitalCodesManagement = () => {
	const userProfile = useUserProfile();
	const {
		canReview,
		canApprove,
		canViewCompanies,
		canCreateOrder,
		canViewActivation, 
		isPublisher,
	} = useMemo(() => getPermissions(userProfile), [userProfile]);

	const ordersQuery = useQuery('getDigitalOrders', () => getDigitalOrders());
	const purposesQuery = useQuery('getDigitalOrdersPlatformLegend', () => getDigitalOrdersPlatformLegend());
	const companiesQuery = useQuery(
		'getAllCompanies',
		() => getAllCompanies(),
		{ enabled: canViewCompanies },
	);
	
	const agreementsQuery = useCompanyAgreementsQuery({
		enabled: isPublisher,
	});

	const invalidAgreement = isPublisher && !validateActiveAgreementType(
		agreementsQuery.data?.data,
		companyAgreementConstants.TYPE.DIGITAL,
		platformCodes['Nintendo Switch'],
		true,
	);

	// For the time being, this refers to platforms that have platform feature edit enabled
	const availablePlatforms = useMemo(() => {
		return getAvailableFeaturePlatforms(userProfile, platformFeatures.DIGITAL_CODES, 'edit');
	}, [userProfile]);

	const isLoading =
		ordersQuery.isLoading ||
		purposesQuery.isLoading ||
		(isPublisher && agreementsQuery.isLoading) ||
		(canViewCompanies && companiesQuery.isLoading);
	const isSuccess =
		ordersQuery.isSuccess &&
		purposesQuery.isSuccess &&
		(!canViewCompanies || companiesQuery.isSuccess);

	const tableFormat = useMemo(
		() =>
			(isSuccess &&
				getTableFormat({
					canCreateOrder,
					canViewCompanies,
					companies: (canViewCompanies && companiesQuery.data.data) || null,
					invalidAgreement,
					availablePlatforms,
				})) ||
			[],
		[
			isSuccess,
			canCreateOrder,
			canViewCompanies,
			companiesQuery.data?.data,
			invalidAgreement,
			availablePlatforms,
		],
	);

	const filtersConfig = useMemo(
		() =>
			userProfile &&
			getFiltersConfig({
				isReviewer: canReview,
				isApprover: canApprove,
				isActivator: canViewActivation,
				purposesData: purposesQuery.data?.data,
			}),
		[userProfile, canReview, canApprove, purposesQuery.isSuccess],
	);

	const orders = isSuccess ? (
		ordersQuery.data.data.body.map((order) => {
			const item = {
				...order,
				game_code: order?.product?.game_code,
				game_name: order?.product?.game_name,
				code_activation: getActivationStatus(order),
			};
			return item;
		})
	) : [];

	const fault = (ordersQuery.isError && ordersQuery.error) ||
		(purposesQuery.isError && purposesQuery.error) ||
		(companiesQuery.isError && companiesQuery.error) ||
		null;

	return (
		<Page fault={fault} isLoading={isLoading}>
			{invalidAgreement &&
				(agreementsQuery.isSuccess ? (
					<Alert variant="danger">
						The Global Digital agreement has expired. Please renew the agreement in the
						Nintendo Developer Portal to proceed.
					</Alert>
				) : (
					<Alert variant="danger">
						Agreements could not be retrieved and functions that require a valid
						agreement have been disabled. Reload or contact site support for assistance.
					</Alert>
				))}
			<Title
				title="Digital Code Orders"
				button={
					canCreateOrder &&
					!invalidAgreement && (
						<Link to={'/orders/digital-codes/create'}>
							<Button variant="primary">
								<FAIcon name="plus" className="mr-1" />
								Create Digital Code Order
							</Button>
						</Link>
					)
				}
			/>
			{!isLoading && (
				<FilterableTable
					dataFormat={tableFormat}
					data={orders}
					searchableFieldPlaceHolder={'Search by Product, Game Code or Publisher PO #'}
					searchableFields={[
						'game_code',
						'game_name',
						'ebsSalesOrderNum',
						'publisherPO',
						'orderID',
					]}
					filterProperties={filtersConfig}
					defaultSorted={[
						{
							id: 'reqActivationDate',
							desc: true,
						},
					]}
					retainPageState
				/>
			)}
		</Page>
	);
};

export default DigitalCodesManagement;
