import React, { Component } from 'react';
import { Alert, Button, FormCheck, Table } from 'react-bootstrap';

import BaseModal from '../../BaseModal/BaseModal';
import {
	getPackoutFacilitiesList,
	postPackoutFacilityAssociation,
} from '../../../services/ordersService';
import { removeItem } from '../../../utils/arrayUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';


export class AssociatePackoutFacilityModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			formData: {},
			allFacilities: [],
			selectedItems: [],
			isLoading: false
		};
		this.getAllPackoutFacilites = this.getAllPackoutFacilites.bind(this);
		this.updateSelectedItems = this.updateSelectedItems.bind(this);
		this.associateFacility = this.associateFacility.bind(this);
	}

	toggleLoading() {
		this.setState({
			isLoading: !this.state.isLoading,
			itemSelected: false,
			selectedItems: []
		});
	}

	updateSelectedItems(id) {
		let newSelected = this.props.singleSelect ? [] : this.state.selectedItems;
		let selectedValue;

		if (!id) {
			return;
		}

		selectedValue = id;

		if (newSelected.includes(selectedValue)) {
			newSelected = removeItem(newSelected, selectedValue);
		} else {
			newSelected.push(selectedValue);
		}
		this.setState({
			selectedItems: newSelected
		});
	}

	associateFacility() {
		let facility_ids = this.state.selectedItems;
		this.setState({isSubmitting:true});
		let requestArray = [];
		facility_ids.forEach((_id) => {
			requestArray.push(
				postPackoutFacilityAssociation(_id.toString())
			);
		});
		Promise.all(requestArray)
			.then(() => {
				this.props.loadPackoutFacilities(facility_ids[0]);
				this.props.closeModal();
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'updating facility associations'),
					'error',
					error
				);
			})
			.finally(() => {
				this.setState({isSubmitting:false});
			});
	}

	getAllPackoutFacilites() {
		this.toggleLoading();
		getPackoutFacilitiesList('all')
			.then((response) => {
				if (response.status === 200) {
					let facilities = [];
					let existingFacilityIds = this.props.linkedFacilities.map(
						(f) => f.packout_facility_id
					);
					response.data.forEach((facility) => {
						if (
							!existingFacilityIds.includes(
								facility.packout_facility_id
							)
						) {
							facilities.push(facility);
						}
					});
					this.setState({
						allFacilities: facilities
					});
				}
			})
			.catch((error) => {
				toasterNotify(
					createMessageForError(error, 'retrieving facilities'),
					'error',
					error
				);
			})
			.finally(() => {
				this.toggleLoading();
			});
	}

	getModalHeader() {
		return (
			<div>
				<button
					type="button"
					className="close"
					data-dismiss="modal"
					aria-label="Close"
					onClick={this.props.closeModal}
				>
					<span aria-hidden="true">×</span>
				</button>
				<h4 className="modal-title">Associate Packout Facility</h4>
			</div>
		);
	}

	getModalFooter() {
		return (
			<div>
				<Button className="pull-left" onClick={this.props.closeModal}>
					Cancel
				</Button>
				<Button
					variant="primary"
					onClick={this.associateFacility}
					disabled={!this.state.selectedItems.length}
				>
					Associate Selected Facilities
				</Button>
			</div>
		);
	}

	getAddress(facility) {
		const {
			name,
			address_1,
			address_2,
			city,
			state,
			postal_code,
			country_name
		} = facility;
		return (
			<span className="address-info">
				<span>
					<strong>{name}</strong>
				</span>
				<span>{address_1}</span>
				<span>{address_2 !== '' && address_2}</span>
				<span>{`${city}, ${state} ${postal_code}`}</span>
				<span>{country_name}</span>
			</span>
		);
	}

	isChecked(id) {
		const { selectedItems } = this.state;
		return selectedItems.includes(id);
	}

	componentDidMount() {
		this.getAllPackoutFacilites();
	}

	render() {
		return (
			<BaseModal
				size="lg"
				show={this.props.show}
				onCancel={this.props.closeModal}
				isLoading={this.state.isLoading}
				isSubmitting={this.state.isSubmitting}
			>
				<BaseModal.Title>Associate Packout Facility</BaseModal.Title>
				<Alert variant="info">
					Select the packout facilities you would like to use from the pre-approved list
					below. The selected facilities will be available when orders are placed.
				</Alert>
				<Table hover>
					<thead>
						<tr>
							<th></th>
							<th>Facility</th>
							<th>Phone</th>
						</tr>
					</thead>
					<tbody>
						{this.state.allFacilities?.map((facility, key) => {
							return (
								<tr
									key={key}
									onClick={() =>
										this.updateSelectedItems(facility.packout_facility_id)
									}
								>
									<td>
										<FormCheck
											type={this.props.singleSelect ? 'radio' : 'checkbox'}
											name={this.props.singleSelect ? 'singleselect' : undefined}
											id='checkbox-packout-facility'
											data-testid='checkbox-packout-facility'
											disabled={this.state.isSubmitting}
											checked={this.isChecked(facility.packout_facility_id)}
											onChange={() => {
												/* noop */
											}}
										/>
									</td>
									<td>{this.getAddress(facility)}</td>
									<td>{facility.phone}</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
				<BaseModal.Submit
					onClick={this.associateFacility}
					disabled={!this.state.selectedItems.length}
				>
					Associate Selected Facilities
				</BaseModal.Submit>
			</BaseModal>
		);
	}
}

export default AssociatePackoutFacilityModal;
