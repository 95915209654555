import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

import { dateFormat, formatDate } from '../../utils/dateUtils';
import FAIcon from '../FAIcon/FAIcon';

import './DropdownDateFilter.css';


class DropdownDateFilter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isActive: false,
		};
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.getSelectedDate = this.getSelectedDate.bind(this);
	}

	toggleDropdown() {
		this.setState((state) => ({
			isActive: !state.isActive,
		}));
	}

	clearAllValues() {
		const { clearFilter, filterName } = this.props;
		clearFilter(filterName);
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState({ isActive: false });
		}
	}

	getSelectedDate(index) {
		return Array.from(this.props.selectedValues)[index];
	}

	setDateRange(value, order) {
		const {clearFilter, addFilter, filterName, selectedValues} = this.props;
		const selectedDateRange = Array.from(selectedValues);

		selectedDateRange[order] = value;
		if (order === 1 && !selectedDateRange[0]) {
			selectedDateRange[0] = new Date(selectedDateRange[1]);
		}
		if ((!selectedDateRange[0] && selectedDateRange[1]) || (selectedDateRange[0] > selectedDateRange[1])) {
			selectedDateRange[1] = null;
		}

		clearFilter(filterName);
		if (selectedDateRange[0]) {
			addFilter(filterName, selectedDateRange[0]);
		}
		if (selectedDateRange[1]) {
			addFilter(filterName, selectedDateRange[1]);
		}
	}

	formattedSelectedValues() {
		const { selectedValues } = this.props;
		const selectedDateRange = Array.from(selectedValues)
			.sort((a, b) => a - b)
			.map((value) => formatDate(value, dateFormat.DATE));
		selectedDateRange[1] = selectedDateRange[1] || '';

		return (
			<strong>
				{selectedDateRange[0]} &ndash;{selectedDateRange[1] ? ` ${selectedDateRange[1]}` : ''}
			</strong>
		);
	}

	render() {
		const { isActive } = this.state;
		const { filterName, selectedValues } = this.props;

		const filterDisplay =
			selectedValues.size === 0 ? (
				filterName
			) : (
				<>
					{filterName}: <b>{this.formattedSelectedValues()}</b>
				</>
			);
		return (
			<li
				role="presentation"
				className={`DropdownFilter__dropdown DropdownDateFilter__dropdown-menu ${
					isActive
						? 'DropdownFilter__dropdown--open dropdown open'
						: 'DropdownFilter__dropdown dropdown'
				}`}
				ref={this.setWrapperRef}
			>
				<a
					className="dropdown-toggle dropdown-datetime-toggle"
					onClick={() => this.toggleDropdown()}
				>
					{filterDisplay}
				</a>
				<ul className={isActive ? 'dropdown-menu show' : 'dropdown-menu'}>
					<header className="dropdown-menu-header">
						<div
							className="dropdown-menu-header-link red"
							onClick={() => this.clearAllValues()}
						>
							Clear All
						</div>
					</header>
					<div className="DropdownDateFilter__dropdown-menu-contents">
						<label className="m-0" htmlFor={'from-field_' + filterName}><h6 className="DropdownDatefilter__heading">Between</h6></label>
						<DatePicker
							id={'from-field_' + filterName}
							className="form-control DropdownDateFilter__input"
							selected={this.getSelectedDate(0)}
							onChange={(value) => this.setDateRange(value, 0)}
							selectsRange
						/>
						<FAIcon
							name="calendar"
							className="date-glyph form-control-feedback DropdownDatefilter__icon"
						/>
					</div>
					<div className="DropdownDateFilter__dropdown-menu-contents">
						<label className="m-0" htmlFor={'to-field_' + filterName}><h6 className="DropdownDatefilter__heading">and</h6></label>
						<DatePicker
							id={'to-field_' + filterName}
							className="form-control DropdownDateFilter__input"
							selected={this.getSelectedDate(1)}
							minDate={this.getSelectedDate(0)}
							onChange={(value) => this.setDateRange(value, 1)}
							selectsRange
						/>
						<FAIcon
							name="calendar"
							className="date-glyph form-control-feedback DropdownDatefilter__icon"
						/>
					</div>
				</ul>
			</li>
		);
	}
}

DropdownDateFilter.propTypes = {
	selectedValues: PropTypes.instanceOf(Set)
};

export default DropdownDateFilter;
