import React, { Component } from 'react';
import {
	Alert,
	Col,
	FormCheck,
	FormControl,
	FormGroup,
	FormLabel,
	Row
} from 'react-bootstrap';

import AsperaFileUpload from '../../../components/AsperaFileUpload/AsperaFileUpload';
import BaseModal from '../../../components/BaseModal/BaseModal';
import HelpBlock from '../../../components/HelpBlock/HelpBlock';
import { assetConst } from '../../../constants/assetConstants';
import { fileTransferConst } from '../../../constants/fileTransferConstants';
import { postAssetStatus } from '../../../services/productsService';
import { fileNameHasGameCode, getDATokenForMarketingEventAsset } from '../../../utils/assetUtils';
import { createMessageForError, toasterNotify } from '../../../utils/toaster';

import '../AssetDetails.css';


export default class ApproveAssetModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			approvalComment: '',
			checked: false,
			review_status: 'APPROVED',
			isFilesSelected: false,
			revisionId: null,
			isUploadInitiated: false,
			isProcessing: false,
			selectedFiles: null,
			validFileSelected: false,
			showAsperaError: false,
		};
		this.editStatus = this.editStatus.bind(this);
		this.checkBox = this.checkBox.bind(this);
		this.filesSelected = this.filesSelected.bind(this);
		this.onComplete = this.onComplete.bind(this);
		this.validateFileType = this.validateFileType.bind(this);
		this.updateFilesSelected = this.updateFilesSelected.bind(this);
	}

	async approveAsset() {
		const {
			productId,
			asset,
			closeModal,
			loadAssetDetails,
		} = this.props;
		const { approvalComment, selectedFiles, validFileSelected } = this.state;

		const payload = {
			review_status: this.determineStatus(),
			comment: approvalComment
		};

		if (this.isFinalLabelSubmit()) {
			if (!validFileSelected) {
				this.setState({showAsperaError: true});
				return;
			}
			payload['files'] = selectedFiles;
			payload['file_upload_type']= assetConst.UPLOAD.MODIFIED_FINAL_LABEL;
		}

		this.setState({
			isProcessing: true
		});

		try {
			const response = await postAssetStatus(
				asset.asset_id,
				payload,
				await getDATokenForMarketingEventAsset(asset, 'review'),
			);

			if (this.isFinalLabelSubmit()) {
				this.setState({
					revisionId: response.data.revision_id.toString(),
					isUploadInitiated: true,
					prefetchedTransferSpecs: response.data.transfer_specs && {
						transfer_specs: response.data.transfer_specs,
					},
				});
				toasterNotify('Final label submitted to NCL', 'success');
			} else {
				let message;
				switch (payload.review_status) {
					case assetConst.STATUS.APPROVED_WITH_CHANGES:
					case assetConst.STATUS.APPROVED:
						message = 'Asset approved';
						break;
					case assetConst.STATUS.REVIEWED_WITH_CHANGES:
					case assetConst.STATUS.REVIEWED:
						message = 'Asset reviewed';
						break;
					case assetConst.STATUS.SUBMITTED_TO_NCL:
						message = 'Asset submitted to NCL';
						break;
					default:
						message = 'Asset approved';
				}
				toasterNotify(message, 'success');
				loadAssetDetails(productId, asset.asset_id);
				closeModal();
			}
		} catch (error) {
			toasterNotify(
				createMessageForError(error, 'approving asset'),
				'error',
				error
			);
		} finally {
			this.setState({ isProcessing: false });
 		}
	}

	determineStatus() {
		const { review_status } = this.state;
		const { asset } = this.props;

		let status = '';

		if (this.isApprovedNextStatus()) {
			return assetConst.STATUS.APPROVED;
		}
		if (
			asset.valid_next_review_statuses_for_user.includes(
				assetConst.STATUS.REVIEWED
			)
		) {
			if (review_status === assetConst.STATUS.APPROVED_WITH_CHANGES) {
				status = assetConst.STATUS.REVIEWED_WITH_CHANGES;
			} else {
				status = assetConst.STATUS.REVIEWED;
			}
		} else if (
			asset.valid_next_review_statuses_for_user.includes(
				assetConst.STATUS.APPROVED
			)
		) {
			status = review_status;
		} else if (
			asset.valid_next_review_statuses_for_user.includes(
				assetConst.STATUS.SUBMIT_TO_NCL_FILE_UPLOADING
			)
		) {
			status = assetConst.STATUS.SUBMIT_TO_NCL_FILE_UPLOADING;
		}

		return status;
	}

	editStatus(e) {
		this.setState({
			review_status: e.target.value
		});
	}

	getAlertMessage() {
		const { asset, currentStatus } = this.props;

		let message =
			'By approving this Asset, a notification will be sent to the publisher that Asset has been approved.';

		if (asset.asset_category === assetConst.CATEGORY.MARKETING_EVENT) {
			message =
				"Currently Marketing Events doesn't support notifications and none will be sent. " +
				'In the near future this will become a part of this feature.';
		} else if (currentStatus === assetConst.STATUS.PENDING_PROOF_ACCEPT) {
			message =
				'A proof of your final label is provided below. Selecting I Accept and Confirm will send an approval notification to the production facility. If the final label is not as expected please contact your account administrator for changes.';
		} else if (currentStatus === assetConst.STATUS.FINAL_LABEL_UPLOADED) {
			message = '';
		} else if (asset.asset_type === assetConst.TYPE.LABEL) {
			message =
				'By approving this Label, a notification will be sent to the publisher to upload Final Label assets.';
		} else if (currentStatus === assetConst.STATUS.PRINT_PROOF_PENDING) {
			message = 'Please click "Confirm" to approve the Print Proof for production purposes.';
		}

		return message && <Alert variant="info">{message}</Alert>;
	}

	getTitle() {
		const { currentStatus } = this.props;

		let title = 'Approve Asset';

		if (currentStatus === assetConst.STATUS.PRINT_PROOF_PENDING) {
			title = 'Approve for Production';
		} else if (currentStatus === assetConst.STATUS.PENDING_PROOF_ACCEPT) {
			title = 'Accept Final Label Approval';
		} else if (currentStatus === assetConst.STATUS.FINAL_LABEL_UPLOADED) {
			title = 'Approve & Send Final Label File to NCL';
		} else if (currentStatus === assetConst.STATUS.SUBMIT_TO_NCL_UPLOAD_FAILED) {
			title = 'Resubmit to NCL';
		}

		return title;
	}

	filesSelected(isFilesSelected) {
		this.setState({
			isFilesSelected: isFilesSelected
		});
	}

	onComplete() {
		const { productId, asset, closeModal, loadAssetDetails } = this.props;

		loadAssetDetails(productId, asset.asset_id);
		closeModal();
	}

	checkBox() {
		const { checked } = this.state;
		this.setState({
			checked: !checked
		});
	}

	updateFilesSelected(isFilesSelected) {
		this.setState({
			isFilesSelected: isFilesSelected,
			showAsperaError: false,
		});
	}

	validateFileType(selectedFiles) {
		const { product } = this.props;

		const isValid =
			selectedFiles.length > 0 &&
			selectedFiles.every((item) => fileNameHasGameCode(item.file_name, product.game_code));

		this.setState({
			validFileSelected: selectedFiles?.length > 0 && isValid,
			selectedFiles: selectedFiles?.length > 0 ? selectedFiles : null,
		});
	}

	isFinalLabelSubmit() {
		const { currentStatus } = this.props;
		const { FINAL_LABEL_UPLOADED, SUBMIT_TO_NCL_UPLOAD_FAILED } = assetConst.STATUS;
		return [FINAL_LABEL_UPLOADED, SUBMIT_TO_NCL_UPLOAD_FAILED].includes(currentStatus);
	}

	isApprovedNextStatus() {
		const { currentStatus, asset } = this.props;

		if (currentStatus !== assetConst.STATUS.PENDING_REVIEW) {
			return false;
		} else if (asset.asset_category === assetConst.CATEGORY.PACKAGING &&
			       asset.asset_type !== assetConst.TYPE.LABEL) {
			return true;
		} else if (asset.asset_category === assetConst.CATEGORY.CODE_IN_BOX) {
			return true;
		} else {
			return false;
		}
	}

	getModalBody() {
		const { currentStatus, asset, product, userProfile } = this.props;
		const {
			approvalComment,
			review_status,
			checked,
			revisionId,
			isUploadInitiated,
			prefetchedTransferSpecs,
			showAsperaError,
		} = this.state;


		if (currentStatus === assetConst.STATUS.PENDING_PROOF_ACCEPT) {
			return (
				<div className="approve-asset-modal">
					<FormGroup as={Row}>
						<Col sm={8}>
							<FormCheck
								type="checkbox"
								id="checkbox"
								className="checkbox"
								checked={checked}
								onChange={this.checkBox}
								label="I Accept"
							/>
						</Col>
					</FormGroup>
				</div>
			);
		} else if (
			currentStatus === assetConst.STATUS.FINAL_LABEL_UPLOADED ||
			currentStatus === assetConst.STATUS.SUBMIT_TO_NCL_UPLOAD_FAILED
		) {
			const finalLabelFiles = [];

			asset.final_label_files.forEach((file, index) => {
				finalLabelFiles.push(
					<FormControl key={'file' + index} as='div' readOnly plaintext>{file.file_name}</FormControl>
				);
			});

			return (
				<div className="approve-asset-modal">
					<FormGroup as={Row}>
						<FormLabel column sm={4} className="text-sm-right">
							Product Name
						</FormLabel>
						<Col sm={8}>
							<FormControl as="div" readOnly plaintext>
								{product.game_name}
							</FormControl>
						</Col>
					</FormGroup>
					<FormGroup as={Row}>
						<FormLabel column sm={4} className="text-sm-right">
							Platform
						</FormLabel>
						<Col sm={8}>
							<FormControl as="div" readOnly plaintext>
								{product.platform_code}
							</FormControl>
						</Col>
					</FormGroup>
					<FormGroup as={Row}>
						<FormLabel column sm={4} className="text-sm-right">
							Game Code
						</FormLabel>
						<Col sm={8}>
							<FormControl as="div" readOnly plaintext>
								{product.game_code}
							</FormControl>
						</Col>
					</FormGroup>
					<FormGroup as={Row}>
						<FormLabel column sm={4} className="text-sm-right">
							Final Label File(s)
						</FormLabel>
						<Col sm={8} style={{ wordBreak: 'break-word' }}>
							{finalLabelFiles}
						</Col>
					</FormGroup>
					<FormGroup as={Row}>
						<FormLabel column sm={4} className="text-sm-right">
							Approval Comments (to NCL)
						</FormLabel>
						<Col sm={8}>
							<FormControl
								as="textarea"
								rows="6"
								value={approvalComment}
								onChange={(e) => {
									this.setState({
										approvalComment: e.target.value,
									});
								}}
								maxLength={2000}
							/>
						</Col>
					</FormGroup>
					<FormGroup>
						<Row>
							<FormLabel column sm={4} className="text-sm-right">
								Attach File
							</FormLabel>
							<Col sm={8} className="mt-2">
								<AsperaFileUpload
									updateFilesSelected={this.updateFilesSelected}
									userProfile={userProfile}
									entityType={
										fileTransferConst.ENTITY_TYPE.PRODUCT_ASSET_REVISION
									}
									entityId={revisionId}
									isUploadInitiated={isUploadInitiated}
									onCompleteFunction={this.onComplete}
									validateFileType={this.validateFileType}
									prefetchedTransferSpecs={prefetchedTransferSpecs}
									singleFileOnly
								/>
								{this.isFinalLabelSubmit() && (
									<HelpBlock variant={showAsperaError && 'danger'}>
										A file upload is required. Selected file must include game
										code ({product.game_code}) in the file name.{' '}
									</HelpBlock>
								)}
							</Col>
						</Row>
					</FormGroup>
				</div>
			);
		} else if (currentStatus !== assetConst.STATUS.PRINT_PROOF_PENDING) {
			return (
				<div className="approve-asset-modal">
					<FormGroup>
						{!this.isApprovedNextStatus() && <>
							<Row>
								<FormLabel column sm={4} className="text-sm-right pt-0">
									Approval Type{' '}
								</FormLabel>
								<Col sm={8}>
									<div className="form-check">
										<input
											className="form-check-input"
											type="radio"
											name="exampleRadios"
											id="exampleRadios1"
											value="APPROVED"
											defaultChecked={review_status === 'APPROVED'}
											onChange={this.editStatus}
										/>
										<label className="form-check-label" htmlFor="exampleRadios1">
											Full Approval
										</label>
									</div>

									<div className="form-check">
										<input
											className="form-check-input"
											type="radio"
											name="exampleRadios"
											id="exampleRadios2"
											value="APPROVED_WITH_CHANGES"
											onChange={this.editStatus}
										/>
										<label className="form-check-label" htmlFor="exampleRadios2">
											Approval with Changes
										</label>
									</div>
									<div className="help-block">Enter changes in comments below.</div>
								</Col>
							</Row>
						</>}
					</FormGroup>
					<FormGroup>
						<Row>
							<FormLabel column sm={4} className="text-sm-right">
								Approval Comments (to publisher)
								{this.isApprovedNextStatus() && <HelpBlock>Optional</HelpBlock>}
							</FormLabel>
							<Col sm={8}>
								<FormControl
									as="textarea"
									rows="6"
									value={approvalComment}
									onChange={(e) => {
										this.setState({
											approvalComment: e.target.value,
										});
									}}
									maxLength={2000}
								/>
							</Col>
						</Row>
					</FormGroup>
				</div>
			);
		}
	}

	render() {
		const { currentStatus, closeModal } = this.props;
		const {
			review_status,
			approvalComment,
			isProcessing,
			checked
		} = this.state;

		let buttonName = 'Confirm';

		if (currentStatus === assetConst.STATUS.FINAL_LABEL_UPLOADED) {
			buttonName = 'Send to NCL';
		}

		const finalLabelApprovalConfirmDisabled =
			currentStatus === assetConst.STATUS.PENDING_PROOF_ACCEPT &&
			!checked;

		return (
			<BaseModal show={true} onCancel={closeModal} isSubmitting={isProcessing}>
				<BaseModal.Title>{this.getTitle()}</BaseModal.Title>
				{this.getAlertMessage()}
				{this.getModalBody()}
				<BaseModal.Submit onClick={() => this.approveAsset()} disabled={
					(review_status ===
						assetConst.STATUS.APPROVED_WITH_CHANGES &&
						approvalComment === '') ||
					isProcessing ||
					finalLabelApprovalConfirmDisabled}>
						{buttonName}
					</BaseModal.Submit>
			</BaseModal>
		);
	}
}
