import React, { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import DigitalCodesOrder from '../../components/DigitalCodesOrder/DigitalCodesOrder';
import FAIcon from '../../components/FAIcon/FAIcon';
import Page from '../../components/Page/Page';
import StatusText from '../../components/StatusText/StatusText';
import Title from '../../components/Title/Title';
import { digitalCodesConstants } from '../../constants/digitalCodesConstants';
import { platformFeatures } from '../../constants/platformConstants';
import { useCompanyAgreementsQuery } from '../../hooks/queryHooks';
import { useUserProfile } from '../../hooks/reduxHooks';
import { getCompanyProfile } from '../../services/companiesService';
import {
	getDigitalOrder,
	getDigitalOrdersPlatformLegend,
} from '../../services/digitalCodesService';
import { getUsers } from '../../services/usersService';
import { formatCurrency } from '../../utils/currencyUtils';
import { getPossiblyInvalidValue, isInvalid } from '../../utils/dataUtils';
import {
	calculateTotalBill,
	getCommentByType,
	getPermissions,
} from '../../utils/digitalCodesUtils';
import { isFeatureActiveForPlatform } from '../../utils/userUtils';
import { modalIdentifiers } from './DigitalCodesDetail.helpers';
import DigitalCodesModals from './modals/DigitalCodesModals';
import DigitalCodesButtons from './views/DigitalCodesButtons';

const {
	AWAITING_PAYMENT_INFO,
	CANCELED,
	CODES_AVAILABLE,
	COMPLETE,
	DOWNLOAD_EXPIRED,
	DRAFT,
	READY_FOR_APPROVAL,
	READY_FOR_REVIEW,
	REJECTED,
} = digitalCodesConstants.status;
const { PAYMENT_MODAL } = modalIdentifiers;

const DigitalCodesDetail = () => {
	const { orderId } = useParams<{ orderId?: string; }>();
	const history = useHistory();
	const [openModal, setOpenModal] = useState<{
		type?: ValuesOf<typeof modalIdentifiers> | null;
		isSubmitting?: boolean;
	}>();

	const userProfile = useUserProfile();
	const {
		canApprove,
		canReview,
		canDownloadCodes,
		canMakePayment,
		canCreateOrder,
		isInternal,
	} = getPermissions(userProfile);

	const purposesQuery = useQuery('getDigitalOrdersPlatformLegend', getDigitalOrdersPlatformLegend);
	const purposes = purposesQuery.data?.data;

	const orderQuery = useQuery(
		['getDigitalOrder', String(orderId)],
		() => getDigitalOrder(String(orderId)),
		{ refetchOnMount: false },
	);
	const order = orderQuery.data?.data?.body;
	const {
		component,
		componentID,
		NDID,
		order_comments,
		platform_code,
		product,
		purposeID,
		sales_flow_version,
		status,
		tax,
		totalPrice,
	} = order || {};

	const publisherQuery = useQuery(
		['getCompanyProfile', NDID],
		() => getCompanyProfile(NDID as string), // crosslinked from orderQuery data
		{ enabled: NDID != null },
	);
	const companyData = publisherQuery.data?.data?.company_information;
	const { company_name } = companyData || {};
	const { game_code, game_name } = product || {};
	const { game_name: componentGameName } = component || {};

	const orderManagersQuery = useQuery(
		['getUsers', { role_name: 'Digital Order Management', company_id: NDID }],
		() => getUsers({ role_name: 'Digital Order Management', company_id: NDID }),
		{ enabled: (canApprove || canReview) && !!NDID },
	);

	const getPurposeNameFromId = (id?: number | null, purposeOutput?: typeof purposes) =>
		(id && purposeOutput
			?.find(
				(entry) => entry?.platform === 'HAC' // harded-coded to HAC for now
			)?.digital_orders_purpose.find(
				(entry) => entry?.digital_orders_purpose_id === id
			)
			?.purpose) || '';


	const agreementsQuery = useCompanyAgreementsQuery({
		enabled:
			canCreateOrder &&
			order?.status === DRAFT
	});

	const [, totalWithTax] = calculateTotalBill({ totalPrice, tax });

	const purposeName = (purposesQuery.isSuccess &&
		getPurposeNameFromId(getPossiblyInvalidValue(purposeID), purposes));

	const productIsUnderTransfer =
		isInvalid(order?.productID)?.code === 'DIGITAL_TRANSFER_IN_PROCESS';

	const isPublisher = userProfile.companyId === order?.NDID;

	const isFeatureActive = !!platform_code && isFeatureActiveForPlatform(
		userProfile,
		platformFeatures.DIGITAL_CODES,
		platform_code,
		'edit',
	);

	const showSubmittersName = isPublisher || isInternal;
	const showSubmittersEmail = showSubmittersName && isInternal;
	const linkSubmittersEmail = showSubmittersEmail && (canApprove || canReview);

	const rejectComment = getCommentByType(order_comments, 'REJECTION');

	const priceInformation = {
		taxPercent: order?.tax ? order.tax : '0.00',
		taxAmount: order?.tax_amount ? order.tax_amount : '0.00',
		grandTotal: order?.grand_total ? order.grand_total : '0.00',
		hasTotalPriceMismatch: false,
		hasUnitPriceFeeMismatch: false,
	};

	return (
		<Page
			isLoading={
				orderQuery.isLoading ||
				purposesQuery.isLoading ||
				agreementsQuery.isLoading ||
				orderManagersQuery.isLoading ||
				publisherQuery.isLoading
			}
			fault={orderQuery.isError && orderQuery.error}
		>
			{canDownloadCodes &&
				status &&
				[CODES_AVAILABLE, COMPLETE, DOWNLOAD_EXPIRED].includes(status) && (
					<Alert variant="info">
						Code batches are delivered with encryption. Please use 7Zip or a tool that
						supports AES-256 encryption to decrypt and unpack the batch.
					</Alert>
				)}
			{status === REJECTED && rejectComment && (
				<Alert variant="danger">
					This order was rejected with the following comment: "{rejectComment}"
				</Alert>
			)}
			{status === CANCELED && (
				<Alert variant="info">This order was cancelled by the publisher</Alert>
			)}
			{[READY_FOR_REVIEW, READY_FOR_APPROVAL].includes(status as string) &&
				rejectComment && (
					<Alert variant="info">
						This order was previously rejected with the following comment: "
						{rejectComment}"
					</Alert>
				)}
			{productIsUnderTransfer && (
				<Alert variant="danger">
					This order includes a product with a pending rights transfer. Some actions are
					disabled until the transfer is completed or rescinded.
				</Alert>
			)}
			<Breadcrumb>
				<Breadcrumb.Item to="/orders/digital-codes">Digital Code Orders</Breadcrumb.Item>
				<Breadcrumb.Item active>View Digital Code Order</Breadcrumb.Item>
			</Breadcrumb>
			<Title
				button={
					<DigitalCodesButtons
						agreements={agreementsQuery.data?.data}
						showModal={(type) => type && setOpenModal({ type: type })}
						orderData={order}
						purposeName={purposeName || ''}
						companyData={companyData}
					/>
				}
				status={
					<StatusText
						badge
						variant={
							sales_flow_version === 2 && status === REJECTED ? 'warning' : undefined
						}
					>
						{status}
					</StatusText>
				}
			>
				View Digital Code Order
			</Title>
			<Page.ContentContainer>
				<DigitalCodesOrder
					publisherIsLoading={publisherQuery.isLoading}
					publisher={((canReview || canApprove) && company_name) || undefined}
					purposeName={purposeName || ''}
					order={order}
					productsComponentsAreLoading={false}
					productsComponents={{
						game_code,
						game_name,
						componentGameName,
						componentID,
					}}
					linkSubmittersEmail={linkSubmittersEmail}
					priceInformation={priceInformation}
					orderManagerEmails={orderManagersQuery.data?.data.map(
						(user) => user.email_address,
					)}
				/>
				{canMakePayment && status === AWAITING_PAYMENT_INFO && (
					<div
						className="my-4 p-4 d-inline-block"
						style={{ background: 'var(--nin-color-ltgray1)' }}
					>
						<h3>Total Amount: {formatCurrency(totalWithTax)}</h3>
					</div>
				)}
				<div className="btn-container">
					<div className="float-left">
						<Button
							variant="outline-secondary"
							onClick={() => history.push('/orders/digital-codes')}
						>
							<FAIcon name="chevron-left" className="mr-1" />
							Back to Digital Code Orders
						</Button>
					</div>
					{canMakePayment && isFeatureActive && status === AWAITING_PAYMENT_INFO && (
						<div className="float-right">
							<Button
								variant="primary"
								onClick={() => setOpenModal({ type: PAYMENT_MODAL })}
							>
								Process Order Payment
							</Button>
						</div>
					)}
				</div>
			</Page.ContentContainer>
			<DigitalCodesModals
				openModal={openModal}
				closeModal={() => setOpenModal({ ...openModal, type: null })}
				order={order}
				product={product}
				isCodeInBox={purposeID === 5}
				onSubmit={() => orderQuery.refetch()}
			/>
		</Page>
	);
};
export default DigitalCodesDetail;
